.loading-indicator-container {
  width: 22rem;
  height: 22rem;
  border-radius: 0.8rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  font-size: 1.8rem;
  line-height: 1.8rem;

  background: #fff;
  box-shadow: 0 0 12px #00000080;
}

@keyframes rotation {
  100% {
    transform: rotate(360deg);
  }
}

.loading-indicator-container svg {
  font-size: 3.2rem;
  line-height: 3.2rem;
  animation: rotation 2.5s linear infinite;
}

.loading-indicator-container span {
  margin-top: 1rem;
}
